import React from "react"
import { navigate } from "gatsby"

import Layout from "layout"
import Hero from "layout/Hero"
import Container from "layout/Container"
import Button from "../../Elements/Button"

import useMedEnsureImages from "./hooks/useMedEnsureImages"
import HomeProgramBenefits from "./HomeProgramBenefits"
import HomeSearch from "./HomeSearch"

const MentalHealth = (props) => {
  const { pageContext } = props
  const { nextPath } = pageContext
  const data = useMedEnsureImages()
  const heart = data.heart.childImageSharp.fluid.src

  return (
    <Layout
      pageContext={pageContext}
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Foundever MedEnsure"
    >
      <Hero
        size="medium"
        background={{
          image: heart,
          position: "center",
        }}
      >
        <Container isCentered desktop={10} fullhd={8}>
          <Container mobile={12} tablet={8} desktop={8} padding={0}>
            <div className="mb-2">
              <h1 className="title has-text-primary">
                Meds at your <br className="is-hidden-mobile" /> convenience.
              </h1>

              <h3 className="mt-2 subtitle">
                Request essential medicines once through{" "}
                <br className="is-hidden-mobile" />
                <span className="has-text-weight-bold has-text-primary">
                  MedEnsure
                </span>{" "}
                and get continuous refills.
                <br className="is-hidden-mobile" /> For{" "}
                <span className="has-text-weight-bold">free</span>.
              </h3>
            </div>
            <Button
              color="orange"
              size="medium"
              onClick={() => navigate(nextPath)}
            >
              Request Medicines
            </Button>
          </Container>
        </Container>
      </Hero>
      <HomeProgramBenefits />
      <Hero>
        <Container isCentered fullhd={8} desktop={10}>
          <HomeSearch />
        </Container>
      </Hero>
    </Layout>
  )
}

export default MentalHealth
