import React, { Fragment } from "react"

import Hero from "layout/Hero"
import Container from "layout/Container"
import MediaBox from "elements/MediaBox"

import useMedEnsureImages from "./hooks/useMedEnsureImages"
import programBenefits from "./utils/programBenefits.json"

const HomeProgramBenefits = () => {
  const data = useMedEnsureImages()
  return (
    <Hero size="small">
      <Container isCentered desktop={10} fullhd={8}>
        <h3 className="my-3 has-text-centered has-text-primary">
          Why MedEnsure?
        </h3>
        <div className="columns is-centered">
          {programBenefits.map((item, index) => (
            <Fragment>
              <MediaBox
                image={{
                  ...data[item?.imageName]?.childImageSharp?.fixed,
                  alt: item.imageName,
                }}
              >
                <p className="has-text-centered">{item?.description}</p>
              </MediaBox>
            </Fragment>
          ))}
        </div>
      </Container>
    </Hero>
  )
}

export default HomeProgramBenefits
